import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { HeaderThird } from 'components/header-third';
import { MainTitle } from 'components/main-title';
import { Paragraph } from 'components/paragraph';
import { StyledLi } from 'components/styled-li';
import { CONSTANTS } from 'constants/styles/constants';

const SHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    text-align: center;
`;

const STermsServiceView = styled(Container)`
    margin-top: ${CONSTANTS.MARGIN.PAGE};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const STextWrapper = styled.div`
    margin-bottom: 2rem;
`;

const STitle = styled(HeaderThird)`
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const SContent = styled.div`
    line-height: 1.8;
`;

const SList = styled.ul`
    list-style-type: decimal-leading-zero;
`;

const SListElement = styled(StyledLi)`
    margin-left: 3.5rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    font-size: 1.2rem;
    font-weight: normal;
`;

export const TermsServiceView = () => {
    return (
        <STermsServiceView>
            <SHeader>
                <MainTitle>
                    <FormattedMessage id="view-terms-service.header" />
                </MainTitle>
                <SHeaderSecond>
                    <FormattedMessage id="view-terms-service.subheader" />
                </SHeaderSecond>
            </SHeader>
            <SContent>
                <STextWrapper>
                    <STitle>
                        <FormattedMessage id="view-terms-service.section1Header" />
                    </STitle>
                    <Paragraph>
                        <FormattedMessage id="view-terms-service.section1Text1" />
                    </Paragraph>
                </STextWrapper>
                <STextWrapper>
                    <STitle>
                        <FormattedMessage id="view-terms-service.section2Header" />
                    </STitle>
                    <Paragraph>
                        <FormattedMessage id="view-terms-service.section2Text1" />
                    </Paragraph>
                    <SList>
                        <SListElement>
                            <FormattedMessage id="view-terms-service.section2Point1" />
                        </SListElement>
                        <SListElement>
                            <FormattedMessage id="view-terms-service.section2Point2" />
                        </SListElement>
                        <SListElement>
                            <FormattedMessage id="view-terms-service.section2Point3" />
                        </SListElement>
                        <SListElement>
                            <FormattedMessage id="view-terms-service.section2Point4" />
                        </SListElement>
                        <SListElement>
                            <FormattedMessage id="view-terms-service.section2Point5" />
                        </SListElement>
                    </SList>
                </STextWrapper>
                <STextWrapper>
                    <STitle>
                        <FormattedMessage id="view-terms-service.section3Header" />
                    </STitle>
                    <Paragraph>
                        <FormattedMessage id="view-terms-service.section3Text1" />
                    </Paragraph>
                </STextWrapper>
                <STextWrapper>
                    <STitle>
                        <FormattedMessage id="view-terms-service.section4Header" />
                    </STitle>
                    <Paragraph>
                        <FormattedMessage id="view-terms-service.section4Text1" />
                    </Paragraph>
                </STextWrapper>
                <STextWrapper>
                    <STitle>
                        <FormattedMessage id="view-terms-service.section5Header" />
                    </STitle>
                    <Paragraph>
                        <FormattedMessage id="view-terms-service.section5Text1" />
                    </Paragraph>
                </STextWrapper>
                <STextWrapper>
                    <STitle>
                        <FormattedMessage id="view-terms-service.section6Header" />
                    </STitle>
                    <Paragraph>
                        <FormattedMessage id="view-terms-service.section6Text1" />
                    </Paragraph>
                </STextWrapper>
                <STextWrapper>
                    <STitle>
                        <FormattedMessage id="view-terms-service.section7Header" />
                    </STitle>
                    <Paragraph>
                        <FormattedMessage id="view-terms-service.section7Text1" />
                    </Paragraph>
                </STextWrapper>
                <STextWrapper>
                    <STitle>
                        <FormattedMessage id="view-terms-service.section8Header" />
                    </STitle>
                    <Paragraph>
                        <FormattedMessage id="view-terms-service.section8Text1" />
                    </Paragraph>
                </STextWrapper>
            </SContent>
        </STermsServiceView>
    );
};
